/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function ($) {
  // image slider

  $('.main-slider a.link-disabled').on('click', function(event) {
    return false;
  });

  $('.main-slider > a').on('click', function (event) {
    event.preventDefault();

    var btn = $(event.currentTarget),
      images = btn.siblings('div'),
      index = images.index(images.filter(':visible')),
      next = btn.hasClass('next'),
      newIndex = 0;

    if (next) {
      if (index < images.length - 1) {
        newIndex = index + 1;
      } else {
        newIndex = 0;
      }

      // images.hide();
      images.css("display", "flex").hide().eq(newIndex).fadeIn();
    } else {
      if (index > 0) {
        newIndex = index - 1;
      } else {
        newIndex = images.length - 1;
      }

      // images.hide();
      images.css("display", "flex").hide().eq(newIndex).fadeIn();

    }
    btn.blur();
  });

})(jQuery);

jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
});

/**
 * hide open elementor accoridion elements on load
 */
jQuery( window ).load(function() {
  if (window.console) {
    console.log('window load fired',jQuery('.elementor-tab-title.elementor-active'));
  }
  window.setTimeout(function() {
    jQuery('.elementor-tab-title.elementor-active').each(function() {
      jQuery(this).removeClass('elementor-active');
      jQuery(this).next().removeClass('elementor-active');
      jQuery(this).next().hide();
    });
  }, 100)
});

jQuery(".img-responsive").swipe( {
  //Generic swipe handler for all directions
  swipeRight:function(event, direction, distance, duration, fingerCount) {
    //$(this).parent().main-slider('prev');
  },
  swipeLeft: function() {
    //$(this).parent().main-slider('next');
  },
  //Default is 75px, set to 0 for demo so any distance triggers swipe
  threshold:0
});


jQuery(document).ready(function() {
  var el = jQuery("#eucookie-notice");
  var button = jQuery("#eucookie-notice button");
  if (- 1 === document.cookie.indexOf("eucookie")) {
    setTimeout(function() { el.slideDown("slow");  }, 1000);
  }
  button.on("click", function() {
    var date = new Date();
    date.setDate(date.getDate() + 365);
    document.cookie = "eucookie=" + escape("hide") + ";path=/;expires=" + date.toGMTString();
    el.slideUp("slow");
  });
});